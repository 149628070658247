import React from 'react'
import { Link } from 'gatsby'
import InfoBar from './InfoBar'
import MobileNav from './MobileNav'
import DesktopNav from './DesktopNav'
import { phoneNumber } from '../utils/config'
import AceLogo from '../images/ace-logo.png'
import MenuOpen from '../images/menu-open.svg'

import { openMenu } from '../utils/menuSlide'

function Header() {
  return (
    <div className="sticky-header">
      <InfoBar />
      <header className="bg--white shadow-md">
        <div className="container container--header">
          <Link className="link--logo-wrap" to="/">
            <img
              className="logo--header"
              src={AceLogo}
              alt="falmouth ace hardware"
            />
          </Link>
          <button
            className="button hamburger bg--transparent"
            type="button"
            onClick={openMenu}
          >
            <img src={MenuOpen} alt="open nav" />
          </button>
          <DesktopNav />
          <MobileNav id="mmenu" />
          <a href={`tel:${phoneNumber}`} className="button">
            <div className="button-call shadow">Call (207) 210-6680</div>
          </a>
        </div>
      </header>
    </div>
  )
}

export default Header
