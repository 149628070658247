import React, { Fragment } from 'react'
import SEO from './SEO'
import Header from './Header'
import Footer from './Footer'
import '../scss/index.scss'

function Layout({ children, location, pageTitle }) {
  return (
    <Fragment>
      <SEO pathname={location} title={pageTitle} />
      <Header />
      {children}
      <Footer />
    </Fragment>
  )
}

export default Layout
