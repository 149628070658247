module.exports = {
  localAdUrl:
    'https://acehardware.shoplocal.com/AceHardwareCircular/Entry/LandingContent?storeid=2626434&sneakpeek=N&listingid=0',
  facebookUrl: 'https://www.facebook.com/FalmouthAceHardware/',
  phoneNumber: '207-210-6680',
  faxNumber: '207-210-6681',
  contactEmail: 'kathryn@falmouthacehardware.com',
  mailchimp: {
    action: `https://falmouthacehardware.us20.list-manage.com/subscribe/post`,
    userId: `ab93ef6e1be1e657918642109`,
    listId: `f3486678dc`,
  },
  storeDescription: `Not only do we offer expert service, we also invite you to take advatage of our in-store pickup for all of your online orders. We carry the largest supply of Carthartt clothing for kids and adults.`,
  imageCredits: {
    homepage: `Photo by Eugen Str on Unsplash`,
  },
}
