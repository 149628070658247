import React from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { getBusinessHours } from '../utils/storeHours'

function InfoBar() {
  const businessHours = getBusinessHours()
  return (
    <div className="bg--red font-caption font--white">
      <div className="container container--infobar">
        <div>
          <FaMapMarkerAlt className="icon-space--sm-right" />
          Falmouth Ace Hardware
        </div>
        <div>{businessHours}</div>
      </div>
    </div>
  )
}

export default InfoBar
