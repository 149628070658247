import React, { Component } from 'react'
import { mailchimp } from '../utils/config'

// add to README.md later...
// https://mailchimp.com/help/host-your-own-signup-forms/
// https://stackoverflow.com/questions/44341293/custom-mailchimp-signup-form-in-react

class NewsletterForm extends Component {
  constructor() {
    super()
    this.state = {
      emailValue: '',
      readOnly: '', // include readOnly empty state to satisfy eslint rule. hidden inputs for bots are intentionall read only
    }
  }

  render() {
    const { action, userId, listId } = mailchimp
    const { emailValue, readOnly } = this.state
    return (
      <form className="footer-form" action={action} method="POST" noValidate>
        <input type="hidden" name="u" value={userId} />
        <input type="hidden" name="id" value={listId} />
        <div className="input-group">
          <label className="font--white font-caption" htmlFor="MERGE0">
            Email Address
          </label>
          <input
            className="form-input"
            placeholder="johnsmith@example.com"
            type="email"
            name="EMAIL"
            id="MERGE0"
            value={emailValue}
            onChange={e => {
              this.setState({ emailValue: e.target.value })
            }}
            autoCapitalize="off"
            autoCorrect="off"
          />
        </div>
        <input
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
          className="form-button"
        />

        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
          aria-label="Please leave the following three fields empty"
        >
          <label htmlFor="b_name">Name:</label>
          <input
            type="text"
            name="b_name"
            tabIndex="-1"
            value={readOnly}
            onChange={e => {
              this.setState({ readOnly: e.target.value })
            }}
            placeholder="Freddie"
            id="b_name"
          />
          <label htmlFor="b_email">Email:</label>
          <input
            type="email"
            name="b_email"
            tabIndex="-1"
            value={readOnly}
            onChange={e => {
              this.setState({ readOnly: e.target.value })
            }}
            placeholder="youremail@gmail.com"
            id="b_email"
          />
          <label htmlFor="b_comment">Comment:</label>
          <textarea
            name="b_comment"
            tabIndex="-1"
            placeholder="Please comment"
            id="b_comment"
          />
        </div>
      </form>
    )
  }
}

export default NewsletterForm
