import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import MenuClose from '../images/menu-close.svg'
import Chevron from '../images/chevron-nav.svg'
import { localAdUrl } from '../utils/config'
import { closeMenu } from '../utils/menuSlide'

function MobileNav() {
  return (
    <StaticQuery
      query={MobileNavQuery}
      render={data => {
        const navItems = data.wordpressWpApiMenusMenusItems.items
        const ShopItems = []
        const CustomerServiceItems = []
        const StoreInfoItems = []
        navItems.map((item, i) => {
          if (i < 2) {
            ShopItems.push(item)
          } else if (i >= 2 && i < 5) {
            CustomerServiceItems.push(item)
          } else {
            StoreInfoItems.push(item)
          }
        })
        return (
          <nav className="nav">
            <div id="m-overlay" className="nav-overlay" />
            <div id="navm" className="nav-container">
              <div className="nav-header">
                <button
                  className="button menuicon-close"
                  type="button"
                  onClick={closeMenu}
                >
                  <img src={MenuClose} alt="close nav" />
                </button>
              </div>
              <div className="nav-list">
                <div className="nav-list--group">
                  <h3 className="category">Shop</h3>
                  {ShopItems.map(item =>
                    item.object_id === 759 ? (
                      <OutboundLink
                        className="category--item"
                        href={localAdUrl}
                        key={item.object_id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                        <img
                          className="chevron"
                          src={Chevron}
                          alt="click item"
                        />
                      </OutboundLink>
                    ) : (
                      <Link
                        className="category--item"
                        key={item.object_id}
                        to={`/${item.object_slug}`}
                      >
                        {item.title}
                        <img
                          className="chevron"
                          src={Chevron}
                          alt="click item"
                        />
                      </Link>
                    )
                  )}
                </div>
                <div className="nav-list--group">
                  <h3 className="category">Customer Service</h3>
                  {CustomerServiceItems.map(item =>
                    item.object_id === 760 ? (
                      <Link
                        className="category--item"
                        key={item.object_id}
                        to="/services#store-pickup"
                      >
                        {item.title}
                        <img
                          className="chevron"
                          src={Chevron}
                          alt="click item"
                        />
                      </Link>
                    ) : (
                      <Link
                        className="category--item"
                        key={item.object_id}
                        to={`/${item.object_slug}`}
                      >
                        {item.title}
                        <img
                          className="chevron"
                          src={Chevron}
                          alt="click item"
                        />
                      </Link>
                    )
                  )}
                </div>
                <div className="nav-list--group">
                  <h3 className="category">Store Info</h3>
                  {StoreInfoItems.map(item =>
                    item.object_id === 761 ? (
                      <Link
                        className="category--item"
                        key={item.object_id}
                        to="/contact#employment"
                      >
                        {item.title}
                        <img
                          className="chevron"
                          src={Chevron}
                          alt="click item"
                        />
                      </Link>
                    ) : (
                      <Link
                        className="category--item"
                        key={item.object_id}
                        to={`/${item.object_slug}`}
                      >
                        {item.title}
                        <img
                          className="chevron"
                          src={Chevron}
                          alt="click item"
                        />
                      </Link>
                    )
                  )}
                </div>
              </div>
            </div>
          </nav>
        )
      }}
    />
  )
}

export default MobileNav

export const MobileNavQuery = graphql`
  query {
    wordpressWpApiMenusMenusItems(slug: { eq: "main-nav" }) {
      items {
        title
        object_id
        object_slug
        url
      }
    }
  }
`
