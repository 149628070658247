import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

function DesktopNav() {
  return (
    <StaticQuery
      query={DesktopNavQuery}
      render={data => {
        const navItems = data.wordpressWpApiMenusMenusItems.items
        return (
          <nav className="dnav">
            <div className="dnav-list">
              {navItems.map(item =>
                item.object_id === 442 ? (
                  <OutboundLink
                    className="dnav-list-item"
                    key={item.object_id}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    activeClassName="active"
                  >
                    {item.title}
                  </OutboundLink>
                ) : (
                  <Link
                    className="dnav-list-item"
                    key={item.object_id}
                    to={`/${item.object_slug}`}
                    activeClassName="active"
                  >
                    {item.title}
                  </Link>
                )
              )}
            </div>
          </nav>
        )
      }}
    />
  )
}

export default DesktopNav

export const DesktopNavQuery = graphql`
  query {
    wordpressWpApiMenusMenusItems(slug: { eq: "desktop-nav" }) {
      items {
        title
        object_id
        object_slug
        url
      }
    }
  }
`
