import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { FaPhone, FaEnvelope, FaFacebook } from 'react-icons/fa'
import NewsletterForm from './NewsletterForm'
import {
  localAdUrl,
  phoneNumber,
  contactEmail,
  facebookUrl,
} from '../utils/config'

function Footer() {
  return (
    <StaticQuery
      query={FooterNavQuery}
      render={data => {
        const navItems = data.wordpressWpApiMenusMenusItems.items
        const ShopItems = []
        const CustomerServiceItems = []
        const StoreInfoItems = []
        navItems.map((item, i) => {
          if (i < 2) {
            ShopItems.push(item)
          } else if (i >= 2 && i < 5) {
            CustomerServiceItems.push(item)
          } else {
            StoreInfoItems.push(item)
          }
        })
        return (
          <footer className="bg--grey700">
            <div className="container">
              <div>
                <nav className="flex--col-row">
                  <div className="flex--item-full nav--footer-group">
                    <h4 className="font--white">Shop</h4>
                    {ShopItems.map(item =>
                      item.object_id === 462 ? (
                        <OutboundLink
                          className="font--white link--footer-nav footer-nav-item"
                          href={localAdUrl}
                          key={item.object_id}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.title}
                        </OutboundLink>
                      ) : (
                        <Link
                          className="font--white link--footer-nav footer-nav-item"
                          key={item.object_id}
                          to={`/${item.object_slug}`}
                        >
                          Departments
                        </Link>
                      )
                    )}
                  </div>
                  <div className="flex--item-full nav--footer-group">
                    <h4 className="font--white">Customer Service</h4>
                    {CustomerServiceItems.map(item =>
                      item.object_id === 753 ? (
                        <Link
                          className="font--white link--footer-nav footer-nav-item"
                          key={item.object_id}
                          to="/services#store-pickup"
                        >
                          {item.title}
                        </Link>
                      ) : (
                        <Link
                          className="font--white link--footer-nav footer-nav-item"
                          key={item.object_id}
                          to={`/${item.object_slug}`}
                        >
                          {item.title}
                        </Link>
                      )
                    )}
                  </div>
                  <div className="flex--item-full nav--footer-group">
                    <h4 className="font--white">Store Info</h4>
                    {StoreInfoItems.map(item =>
                      item.object_id === 756 ? (
                        <Link
                          className="font--white link--footer-nav footer-nav-item"
                          key={item.object_id}
                          to="/contact#employment"
                        >
                          {item.title}
                        </Link>
                      ) : (
                        <Link
                          className="font--white link--footer-nav footer-nav-item"
                          key={item.object_id}
                          to={`/${item.object_slug}`}
                        >
                          {item.title}
                        </Link>
                      )
                    )}
                  </div>
                </nav>
                <hr className="border-line--grey300" />

                <div className="flex--col-row">
                  <div className="flex--item-full nav--footer-group">
                    <h4 className="font--white">Falmouth Ace Hardware</h4>
                    <div>
                      <p className="font--white">
                        215 U.S. Route 1
                        <br />
                        Falmouth, ME 04105
                        <br />
                        (207) 210-6680
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="flex--item-full nav--footer-group">
                    <h4 className="font--white">
                      Need Help? Ask one of our experts.
                    </h4>
                    <a
                      className="font--white link--footer-nav footer-nav-item"
                      key={1}
                      href={`tel:${phoneNumber}`}
                    >
                      <FaPhone className="icon--footer icon-space--md-right rotate--180" />
                      (207) 210-6680
                    </a>
                    <a
                      className="font--white link--footer-nav footer-nav-item"
                      key={2}
                      href={`mailto:${contactEmail}`}
                    >
                      <FaEnvelope className="icon--footer icon-space--md-right" />
                      Email Us
                    </a>
                    <OutboundLink
                      className="font--white link--footer-nav footer-nav-item"
                      key={3}
                      href={facebookUrl}
                    >
                      <FaFacebook className="icon--footer icon-space--md-right" />
                      Find Us on Facebook
                    </OutboundLink>
                  </div>

                  <div className="flex--item-full nav--footer-group">
                    <h4 className="font--white">Get Exclusive Offers</h4>
                    <NewsletterForm />
                  </div>
                </div>
                {/** footer list container */}
                <div className="font--white copyright">
                  © 2019 Falmouth Ace Hardware.
                </div>
              </div>
            </div>
          </footer>
        )
      }}
    />
  )
}

export default Footer

export const FooterNavQuery = graphql`
  query {
    wordpressWpApiMenusMenusItems(slug: { eq: "footer-nav" }) {
      items {
        title
        object_id
        object_slug
        order
      }
    }
  }
`
