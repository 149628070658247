import moment from 'moment'

function getStartOfDay() {
  const startOfDay = moment('000', 'Hmm').format('H:mm')
  return startOfDay
}

function getCurrentTime() {
  const currentTime = moment().format('H:mm')
  return currentTime
}

function getCurrentDay() {
  const currentDay = moment().format('dddd')
  return currentDay
}

function getOpeningTime() {
  const openingTime = moment('900', 'Hmm').format('H:mm')
  return openingTime
}

function getClosingTime() {
  // const today = getCurrentDay()
  // if (today === 'Sunday') {
  //   closingTime = moment('1700', 'Hmm').format('H:mm')
  // } else {
  //   closingTime = moment('1800', 'Hmm').format('H:mm')
  // }
  const closingTime = moment('1700', 'Hmm').format('H:mm')
  return closingTime
}

function formatForDisplay(time) {
  return moment(time, 'H:mm').format('h:mm A')
}

export function getBusinessHours() {
  const open = getOpeningTime()
  const close = getClosingTime()
  const now = getCurrentTime()

  /*
    works for now as operations open/close at the top of the hour
    will need to refactor again, if open/close hours change to partial hours (i.e. 5:30)
  */
  const openTime = parseInt(open, 10)
  const closeTime = parseInt(close, 10)
  const currentTime = parseInt(now, 10)

  if (currentTime < openTime) {
    const displayTime = formatForDisplay(openTime)
    return `Opens today at ${displayTime}`
  }
  if (currentTime >= closeTime) {
    const displayTime = formatForDisplay(openTime)
    return `Opens tomorrow at ${displayTime}`
  }
  const displayTime = formatForDisplay(closeTime)
  return `Open today until ${displayTime}`
}
