export function openMenu() {
  const nav = document.querySelector('#navm')

  window.setTimeout(() => {
    nav.classList.remove('slide-out')
    nav.classList.add('slide-in')
  }, 50)
}

export function closeMenu() {
  const nav = document.querySelector('#navm')

  window.setTimeout(() => {
    nav.classList.remove('slide-in')
    nav.classList.add('slide-out')
  }, 50)
}
